import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, CardFooter, CardHeader, Progress } from "reactstrap";
import { Wizard, Step, Steps } from "react-albus";
import { FormattedMessage, useIntl } from "react-intl";
import { ValidationForm } from "react-bootstrap4-form-validation";
import SeriesStep from "./SeriesStep";
import ProductDenominationStep from "./ProductDenominationStep";
import ProductDenominationParametersStep from "./ProductDenominationParametersStep";
import ConceptClassStep from "./ConceptClassStep";
import ProductListStep from "./ProductListStep";
import FrequenciesStep from "./FrequenciesStep";
import MotorPolesStep from "./MotorPolesStep";
import ProductDefinitionStep from "./ProductDefinitionStep";
import ConceptClassCurveStep from "./ConceptClassCurveStep";
import CurveGroupDefinitionStep from "./CurveGroupDefinitionStep";
import SubFrequenciesStep from "./SubFrequenciesStep";
import CurveFrequencyDefinitionStep from "./CurveFrequencyDefinitionStep";
import RegulationVoltagesStep from "./RegulationVoltagesStep";
import CurveVoltageDefinitionStep from "./CurveVoltageDefinitionStep";
import SpeedsStep from "./SpeedsStep";
import CurveSpeedDefinitionStep from "./CurveSpeedDefinitionStep";
import { useProduct } from "../../../context/ProductContext";
import { Link } from "react-router-dom";
import { useSessionStorage } from "../../../utils/useSessionStorage";
import Tooltip from "../../Tooltip";
import { Fragment } from "react";

function SimpleProductWizard(props) {
  const { history } = props;
  const { url } = props.match;
  const {
    productConceptsProvider,
    productTechnicalAttributesProvider,
    productCurveGroupAttributesProvider,
    productCurveAttributesProvider,
    serieComercialLister,
    neededAttributesProvider,
  } = props;

  const intl = useIntl();

  const { updateProducts } = useProduct();

  // Series
  const [series, setSeries] = useSessionStorage("Genesis.ProductWizard.Series", "");

  // Product attributes
  const [productAttributes, setProductAttributes] = useState();

  // Concept class
  const [conceptClassUnic, setConceptClass] = useSessionStorage("Genesis.ProductWizard.Concept", "");
  const [conceptClasses, setConceptClasses] = useState([]);

  // Needed Attributes
  const [neededAttributes, setNeededAttributes] = useState([]);

  // Product denomination
  const [denomination, setDenomination] = useSessionStorage("Genesis.ProductWizard.Denomination", "");

  // Product denomination parameters
  const [denominationParameter, setDenominationParameter] = useSessionStorage("Genesis.ProductWizard.DenominationParameter", "");
  const [customDenominationParameter, setCustomDenominationParameter] = useSessionStorage("Genesis.ProductWizard.CustomDenominationParameter", "");

  // Product list
  const [productList, setProductList] = useSessionStorage("Genesis.ProductWizard.ProductList", [""]);

  // Frequencies
  const [availableFrequencies, setAvailableFrequencies] = useState([]);
  const [frequencies, setFrequencies] = useSessionStorage("Genesis.ProductWizard.Frequencies", []);

  // Motor options
  const [availablePoles, setAvailablePoles] = useState([]);
  const [poles, setPoles] = useSessionStorage("Genesis.ProductWizard.Poles", []);

  // Product definitions
  const [productPoles, setProductPoles] = useSessionStorage("Genesis.ProductWizard.ProductPoles", []);

  // Curve concept class
  const [conceptClassCurve, setConceptClassCurve] = useSessionStorage("Genesis.ProductWizard.CurveConcept", undefined);

  // Group and curve attributes
  const [groupAttributes, setGroupAttributes] = useState();
  const [curveAttributes, setCurveAttributes] = useState();

  // Curve group definition
  const [availableFilterPrincipalMG, setAvailableFilterPrincipalMG] = useState([]);
  const [filterPrincipalMG, setFilterPrincipalMG] = useSessionStorage("Genesis.ProductWizard.FilterPrincipalMG", [{ name: "none" }]);
  const [customFilterPrincipalMG, setCustomFilterPrincipalMG] = useSessionStorage("Genesis.ProductWizard.CustomFilterPrincipalMG", [{ id: 0, principal: true }]);

  // Sub Frequencies
  const [subFrequencies, setSubFrequencies] = useSessionStorage("Genesis.ProductWizard.SubFrequencies", []);
  const [customSubFrequencies, setCustomSubFrequencies] = useSessionStorage("Genesis.ProductWizard.CustomSubFrequencies", [{ id: 0, principal: true }]);

  // Curves definition by group
  const [products, setProducts] = useSessionStorage("Genesis.ProductWizard.Products", []);

  // Regulation voltages
  const [voltages, setVoltages] = useSessionStorage("Genesis.ProductWizard.Voltages", []);
  const [customVoltages, setCustomVoltages] = useSessionStorage("Genesis.ProductWizard.CustomVoltages", [{ id: 0, principal: true }]);

  // Speeds
  const [speeds, setSpeeds] = useSessionStorage("Genesis.ProductWizard.Speeds", []);
  const [customSpeeds, setCustomSpeeds] = useSessionStorage("Genesis.ProductWizard.CustomSpeeds", [{ id: 0, principal: true }]);

  // Final products
  const [finalProducts, setFinalProducts] = useState([]);

  useEffect(() => {
    getConcepts();
  }, [series]);

  useEffect(() => {
    getAttributes();
  }, [conceptClassUnic]);

  async function getConcepts() {
    if (!series) return;
    setConceptClasses(await productConceptsProvider.get(series.id));
  }

  async function getAttributes() {
    if (!conceptClassUnic) return;
    setProductAttributes(await productTechnicalAttributesProvider.get(conceptClassUnic));
    setNeededAttributes(await neededAttributesProvider.get(conceptClassUnic));
  }

  function attributeIsValid(attributes, attributeClass) {
    return attributes && attributes[attributeClass] && attributes[attributeClass].isVisible;
  }

  // Frequencies

  useEffect(() => {
    setAvailableFrequencies(getFrequencies());
  }, [productAttributes]);

  function getFrequencies() {
    if (!attributeIsValid(productAttributes, "codFrecuencia")) return [];

    return productAttributes["codFrecuencia"].value.options.map((o) => {
      return { id: o.id, frequency: o.value };
    });
  }

  // Motor options
  useEffect(() => {
    setAvailablePoles(getPoles());
  }, [productAttributes]);

  function getPoles() {
    if (!attributeIsValid(productAttributes, "polos")) return [];

    return productAttributes["polos"].value.options
      .filter((o) => o.id !== "none")
      .map((o) => {
        return { id: o.id, pole: o.value };
      });
  }

  // Product definitions

  function buildProductPoles() {
    let products = [];
    let id = 0;

    frequencies.forEach((frequency) => {
      productList.forEach((product) => {
        if (product === "") return;
        products.push({
          id: ++id,
          denominationParameter: product,
          frequency: frequency,
          poles: [...poles],
        });
      });
    });

    setProductPoles(products);
  }

  useEffect(() => {
    buildProductPoles();
  }, [frequencies, poles, productList]);

  // Group and curve attributes

  useEffect(() => {
    getGroupAttributes();
  }, [conceptClassCurve]);

  async function getGroupAttributes() {
    if (conceptClassCurve) {
      setCurveAttributes(await productCurveAttributesProvider.get(conceptClassCurve));
      setGroupAttributes(await productCurveGroupAttributesProvider.get(conceptClassCurve));
    }
  }

  // Curve group definition
  const filterPrincipalMGOptions = [
    {
      id: 0,
      values: [{ name: "EXT" }, { name: "IMP", principal: true }],
      text: "product_wizard.principalmg_extract_supply",
    },
    {
      id: 1,
      values: [{ name: "Empty" }, { name: "Supply", principal: true }, { name: "Extract" }],
      text: "product_wizard.principalmg_empty_extract_supply",
    },
    {
      id: 2,
      values: [{ name: "120V" }, { name: "127V", principal: true }, { name: "220V" }],
      text: "product_wizard.principalmg_120v_127v_220v",
    },
    {
      id: 3,
      values: [{ name: "50Hz" }, { name: "60Hz", principal: true }],
      text: "product_wizard.principalmg_50hz_60hz",
    },
    {
      id: 4,
      values: [{ name: "custom" }],
      text: "product_wizard.principalmg_other",
    },
    { id: 5, values: [{ name: "none" }], text: "product_wizard.principalmg_none" },
  ];

  useEffect(() => {
    setAvailableFilterPrincipalMG(getFilterPrincipalMGs());
  }, [groupAttributes]);

  function getFilterPrincipalMGs() {
    if (!attributeIsValid(groupAttributes, "filterPrincipalMG")) return [];
    const allowedFilterPrincipalMGs = groupAttributes["filterPrincipalMG"].value.options;
    return filterPrincipalMGOptions.filter(
      (f) =>
        f.values.some((fv) => fv.name === "custom") || f.values.some((fv) => fv.name === "none") || f.values.some((v) => allowedFilterPrincipalMGs.find((a) => a.value === v.name))
    );
  }

  // Sub Frequencies
  const availableSubFrequencies = [
    {
      id: 0,
      values: [
        { name: "20", value: "20" },
        { name: "30", value: "30" },
        { name: "40", value: "40" },
        { name: "50", value: "1", principal: true },
        { name: "60", value: "2" },
      ],
      text: "20Hz, 30Hz, 40Hz, 50Hz, 60Hz",
    },
    { id: 1, values: [{ name: "custom", value: "custom" }], text: "product_wizard.personalised" },
  ];

  // Curves definition by group

  useEffect(() => {
    buildProducts();
  }, [
    productPoles,
    filterPrincipalMG,
    customFilterPrincipalMG,
    availablePoles,
    availableFrequencies,
    speeds,
    customSpeeds,
    voltages,
    customVoltages,
    subFrequencies,
    customSubFrequencies,
  ]);

  const groups = () => (filterPrincipalMG.some((f) => f.name === "custom") ? customFilterPrincipalMG : filterPrincipalMG);

  function buildProducts() {
    // build products per group and poles, size and frequency
    let productsAndGroups = [];
    let id = 0;
    const g = groups();
    g.forEach((group) => {
      productPoles.forEach((productPole) => {
        let poles = productPole.poles;
        if (poles.length === 0) poles.push(-1);
        poles.forEach((pole) => {
          let name = denomination.toUpperCase();
          if (pole > -1 && availablePoles.length > 0) {
            name += `/${availablePoles.find((p) => p.id === pole).pole}`;
          }
          if (availableFrequencies.length > 0) {
            name += `-${productPole.denominationParameter}-${availableFrequencies.find((f) => f.id === productPole.frequency)?.frequency}Hz`;
          }
          productsAndGroups.push({
            id: ++id,
            name: name,
            group: group,
            pole: pole,
            denominationParameter: productPole.denominationParameter,
            frequency: productPole.frequency,
            principal: null,
            subFrequencies: [],
            voltages: [],
            speeds: [],
          });
        });
      });
    });

    setProducts(productsAndGroups);
  }

  // Regulation voltages

  const availableVoltages = [
    { id: 0, values: [{ name: "4" }, { name: "6" }, { name: "8" }, { name: "10", principal: true }], text: "4V, 6V, 8V, 10V" },
    { id: 1, values: [{ name: "100" }, { name: "140" }, { name: "180" }, { name: "230", principal: true }], text: "100V, 140V, 180V, 230V" },
    { id: 2, values: [{ name: "custom" }], text: "product_wizard.personalised" },
  ];

  // Speeds

  const availableSpeeds = [
    { id: 0, values: [{ name: "V3" }, { name: "V2" }, { name: "V1", principal: true }], text: "V3, V2, V1" },
    { id: 1, values: [{ name: "LS" }, { name: "MS" }, { name: "HS", principal: true }], text: "LS, MS, HS" },
    { id: 2, values: [{ name: "VL" }, { name: "VM" }, { name: "VR", principal: true }], text: "VL, VM, VR" },
    { id: 3, values: [{ name: "custom" }], text: "product_wizard.personalised" },
  ];

  useEffect(() => {
    buildFinalProducts();
  }, [products]);

  const isAHU = (group) => group.conceptClassUnic == conceptClasses?.find((c) => c.value.toUpperCase() == "AHU-CONFIGURABLEHRU")?.id;

  function buildFinalProducts() {
    const distinctModels = [...new Set(products.map((p) => p.name))];
    let finalModels = [];
    distinctModels.forEach((model, modelIndex) => {
      const m = products.filter((p) => p.name === model);
      const g = [...new Set(m.map((p) => p.group))];

      let product = {
        activo: true,
        codModeloProductoGenerico_version: 0,
        name: model,
        id: {
          value: -modelIndex,
          version: 0,
        },
        cuerpo: denomination,
        tamanyNominal: m[0].denominationParameter,
        curveGroups: [],
        polos: m[0].pole,
        codFrecuencia: m[0].frequency,
        size: m[0].denominationParameter,
        conceptClassUnic: conceptClassUnic,
        codModeloMotor: 0,
        series: series,
      };
      finalModels.push(product);

      g.forEach((groupName, groupIndex) => {
        const modelWithGroup = m.find((mg) => mg.group === groupName);
        let group = {
          index: groupIndex,
          activo: true,
          filterPrincipalMG: groupName.name,
          principalMG: groupName.principal || false,
          //principal: groupName.principal || false,
          conceptClassUnic: conceptClassCurve,
          curves: [],
          codModeloDato_Version: 0,
          codFrecuencia: modelWithGroup.frequency,
        };

        let c = modelWithGroup.subFrequencies.length > 0 ? modelWithGroup.subFrequencies : modelWithGroup.voltages.length > 0 ? modelWithGroup.voltages : modelWithGroup.speeds;
        if (!c || c.length === 0) {
          c = [""];
        }

        c.forEach((curve, curveIndex) => {
          group.curves.push({
            index: curveIndex,
            curveId: curveIndex,
            activo: true,
            name: curve.name,
            principal: curve.principal || c.length == 1 || false,
            referenceProduct: curve.principal || c.length == 1 ? 1 : 0,
            tamany: m[0].denominationParameter,
            caudal: null,
            diametro: null,
            cuerpo: denomination,
            flujoAire: isAHU(group) ? group.filterPrincipalMG : undefined,
          });
        });

        if (modelWithGroup.subFrequencies.length > 0) {
          group.curves.forEach((curve, curveIndex) => {
            const f = modelWithGroup.subFrequencies[curveIndex].name;
            curve.frecuencia = f.indexOf("50") > -1 ? 1 : f.indexOf("60") > -1 ? 2 : modelWithGroup.subFrequencies[curveIndex].name.toUpperCase().replace("HZ", "");
          });
        }

        if (modelWithGroup.voltages.length > 0) {
          group.curves.forEach((curve, curveIndex) => {
            curve.tension = modelWithGroup.voltages[curveIndex].name;
          });
        }

        if (modelWithGroup.speeds.length > 0) {
          group.curves.forEach((curve, curveIndex) => {
            curve.speed = modelWithGroup.speeds[curveIndex].name;
            curve.posVelocidad = curve.speed;
          });
        }

        group.curves = group.curves.sort(function (a, b) {
          return a.principal ? -1 : 1;
        });

        product.curveGroups.push(group);
      });
    });

    setFinalProducts(finalModels);
  }

  function lookupConceptClass(conceptClassUnic) {
    if (conceptClasses.length == 0) return conceptClassUnic;
    return conceptClasses.find((c) => c.id == conceptClassUnic)?.value || conceptClassUnic;
  }

  function finish() {
    updateProducts(finalProducts);
    history.push("/products/wizard/0/technical-information");
  }

  const validateNextStep = ({ step, push }) => {
    if (!step.id) return;
    switch (step.id) {
      case "frequencies": {
        neededAttributes && neededAttributes.some((a) => a.definition == "POLOS") ? push() : push("curve-group-definition" /* "curve-concept-class" */);
        break;
      }
      case "curve-group-definition": {
        const cc = conceptClasses.find((c) => c.id === conceptClassCurve);
        if (cc.allowSubFrequencies) push("sub-frequencies-question");
        else if (cc.allowRegulationVoltages) push("regulation-voltages-question");
        else if (cc.allowSpeeds) push("speeds-question");
        else push("finish");
        break;
      }
      case "curve-frequency-definition":
      case "curve-voltage-definition":
      case "curve-speed-definition": {
        push("finish");
        break;
      }
      default:
        push();
    }
  };

  return (
    <Wizard
      history={history}
      basename={url}
      onNext={validateNextStep}
      render={({ step, steps }) => (
        <div>
          <Progress value={((steps.indexOf(step) + 1) / steps.length) * 100} />
          <Steps key={step.id} step={step.id ? step : undefined}>
            <Step id="series" render={({ next }) => <SeriesStep serieComercialLister={serieComercialLister} setSeries={setSeries} next={next}></SeriesStep>} />
            <Step
              id="product-denomination"
              render={({ next, previous }) => (
                <ProductDenominationStep next={next} prev={previous} denomination={denomination} setDenomination={setDenomination}></ProductDenominationStep>
              )}
            />
            <Step
              id="product-denomination-parameters"
              render={({ next, previous }) => (
                <ProductDenominationParametersStep
                  next={next}
                  prev={previous}
                  denominationParameter={denominationParameter}
                  setDenominationParameter={setDenominationParameter}
                  customDenominationParameter={customDenominationParameter}
                  setCustomDenominationParameter={setCustomDenominationParameter}
                ></ProductDenominationParametersStep>
              )}
            />
            <Step
              id="concept-class"
              render={({ next, previous }) => (
                <ConceptClassStep
                  next={next}
                  prev={previous}
                  conceptClassUnic={conceptClassUnic}
                  setConceptClass={setConceptClass}
                  setConceptClassCurve={setConceptClassCurve}
                  conceptClasses={conceptClasses}
                ></ConceptClassStep>
              )}
            />
            <Step
              id="product-list"
              render={({ next, previous }) => (
                <ProductListStep
                  next={next}
                  prev={previous}
                  denomination={denomination}
                  productList={productList}
                  setProductList={setProductList}
                  denominationParameter={denominationParameter}
                  customDenominationParameter={customDenominationParameter}
                ></ProductListStep>
              )}
            />
            <Step
              id="frequencies"
              render={({ next, previous }) => (
                <FrequenciesStep
                  next={next}
                  prev={previous}
                  availableFrequencies={availableFrequencies}
                  frequencies={frequencies}
                  setFrequencies={setFrequencies}
                ></FrequenciesStep>
              )}
            />
            <Step
              id="motor-poles-question"
              render={({ next, previous, push }) => (
                <Card className="mt-2">
                  <CardBody className="text-center">
                    <h2>
                      <FormattedMessage id="product_wizard.different_motor_poles" defaultMessage="Do your products have different motor poles?" />
                    </h2>
                    <div className="text-center">
                      <Button className="btn btn-danger" onClick={next}>
                        <FormattedMessage id="app.yes" defaultMessage="Yes" />
                      </Button>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          setPoles([]);
                          push("curve-group-definition" /* "curve-concept-class" */);
                        }}
                      >
                        <FormattedMessage id="app.no" defaultMessage="No" />
                      </Button>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Button className="btn btn-outline-danger" onClick={previous}>
                      <i className="fas fa-arrow-left"></i> <FormattedMessage id="app.back" defaultMessage="Back" />
                    </Button>
                  </CardFooter>
                </Card>
              )}
            />
            <Step
              id="motor-poles"
              render={({ next, previous }) => <MotorPolesStep next={next} prev={previous} availablePoles={availablePoles} poles={poles} setPoles={setPoles}></MotorPolesStep>}
            />
            <Step
              id="product-definition"
              render={({ next, previous }) => (
                <ProductDefinitionStep
                  next={next}
                  prev={previous}
                  availablePoles={availablePoles}
                  poles={poles}
                  productPoles={productPoles}
                  setProductPoles={setProductPoles}
                  denomination={denomination}
                  availableFrequencies={availableFrequencies}
                ></ProductDefinitionStep>
              )}
            />
            {/* <Step
              id="curve-concept-class"
              render={({ next, previous }) => (
                <ConceptClassCurveStep
                  next={next}
                  prev={previous}
                  conceptClassCurve={conceptClassCurve}
                  setConceptClassCurve={setConceptClassCurve}
                  conceptClasses={conceptClasses}
                ></ConceptClassCurveStep>
              )}
            /> */}
            <Step
              id="curve-group-definition"
              render={({ next, previous }) => (
                <CurveGroupDefinitionStep
                  next={next}
                  prev={previous}
                  availableFilterPrincipalMG={availableFilterPrincipalMG}
                  filterPrincipalMG={filterPrincipalMG}
                  setFilterPrincipalMG={setFilterPrincipalMG}
                  customFilterPrincipalMG={customFilterPrincipalMG}
                  setCustomFilterPrincipalMG={setCustomFilterPrincipalMG}
                ></CurveGroupDefinitionStep>
              )}
            />
            <Step
              id="sub-frequencies-question"
              render={({ next, previous, push }) => (
                <Card className="mt-2">
                  <CardBody className="text-center">
                    <h2>
                      <FormattedMessage id="product_wizard.define_sub_frequencies" defaultMessage="Do you want to define sub-frequencies?" />
                    </h2>
                    <div className="text-center">
                      <Button className="btn btn-danger" onClick={next}>
                        <FormattedMessage id="app.yes" defaultMessage="Yes" />
                      </Button>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          setSubFrequencies([]);
                          push("finish");
                        }}
                      >
                        <FormattedMessage id="app.no" defaultMessage="No" />
                      </Button>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Button className="btn btn-outline-danger" onClick={previous}>
                      <i className="fas fa-arrow-left"></i> <FormattedMessage id="app.back" defaultMessage="Back" />
                    </Button>
                  </CardFooter>
                </Card>
              )}
            />
            <Step
              id="sub-frequencies"
              render={({ next, previous }) => (
                <SubFrequenciesStep
                  next={next}
                  prev={previous}
                  availableSubFrequencies={availableSubFrequencies}
                  subFrequencies={subFrequencies}
                  setSubFrequencies={setSubFrequencies}
                  customSubFrequencies={customSubFrequencies}
                  setCustomSubFrequencies={setCustomSubFrequencies}
                ></SubFrequenciesStep>
              )}
            />
            <Step
              id="curve-frequency-definition"
              render={({ next, previous }) => (
                <CurveFrequencyDefinitionStep
                  next={next}
                  prev={previous}
                  groups={groups()}
                  subFrequencies={subFrequencies}
                  customSubFrequencies={customSubFrequencies}
                  products={products}
                  setProducts={setProducts}
                ></CurveFrequencyDefinitionStep>
              )}
            />
            <Step
              id="regulation-voltages-question"
              render={({ next, previous, push }) => (
                <Card className="mt-2">
                  <CardBody className="text-center">
                    <h2>
                      <FormattedMessage id="product_wizard.do_you_want_to_define_voltages" defaultMessage="Do you want to define regulation voltages?" />
                    </h2>
                    <div className="text-center">
                      <Button className="btn btn-danger" onClick={next}>
                        <FormattedMessage id="app.yes" defaultMessage="Yes" />
                      </Button>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          setVoltages([]);
                          push("finish");
                        }}
                      >
                        <FormattedMessage id="app.no" defaultMessage="No" />
                      </Button>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Button className="btn btn-outline-danger" onClick={previous}>
                      <i className="fas fa-arrow-left"></i> <FormattedMessage id="app.back" defaultMessage="Back" />
                    </Button>
                  </CardFooter>
                </Card>
              )}
            />
            <Step
              id="regulation-voltages"
              render={({ next, previous }) => (
                <RegulationVoltagesStep
                  next={next}
                  prev={previous}
                  availableVoltages={availableVoltages}
                  voltages={voltages}
                  setVoltages={setVoltages}
                  customVoltages={customVoltages}
                  setCustomVoltages={setCustomVoltages}
                ></RegulationVoltagesStep>
              )}
            />
            <Step
              id="curve-voltage-definition"
              render={({ next, previous }) => (
                <CurveVoltageDefinitionStep
                  next={next}
                  prev={previous}
                  groups={groups()}
                  voltages={voltages}
                  customVoltages={customVoltages}
                  products={products}
                  setProducts={setProducts}
                ></CurveVoltageDefinitionStep>
              )}
            />
            <Step
              id="speeds-question"
              render={({ next, previous, push }) => (
                <Card className="mt-2">
                  <CardBody className="text-center">
                    <h2>
                      <FormattedMessage id="product_wizard.do_you_want_define_speeds" defaultMessage="Do you want to define speeds?" />
                    </h2>
                    <div className="text-center">
                      <Button className="btn btn-danger" onClick={next}>
                        <FormattedMessage id="app.yes" defaultMessage="Yes" />
                      </Button>
                      <Button
                        className="btn btn-danger"
                        onClick={() => {
                          setSpeeds([]);
                          push("finish");
                        }}
                      >
                        <FormattedMessage id="app.no" defaultMessage="No" />
                      </Button>
                    </div>
                  </CardBody>
                  <CardFooter>
                    <Button className="btn btn-outline-danger" onClick={previous}>
                      <i className="fas fa-arrow-left"></i> <FormattedMessage id="app.back" defaultMessage="Back" />
                    </Button>
                  </CardFooter>
                </Card>
              )}
            />
            <Step
              id="speeds"
              render={({ next, previous }) => (
                <SpeedsStep
                  next={next}
                  prev={previous}
                  availableSpeeds={availableSpeeds}
                  speeds={speeds}
                  setSpeeds={setSpeeds}
                  customSpeeds={customSpeeds}
                  setCustomSpeeds={setCustomSpeeds}
                ></SpeedsStep>
              )}
            />
            <Step
              id="curve-speed-definition"
              render={({ next, previous }) => (
                <CurveSpeedDefinitionStep
                  next={next}
                  prev={previous}
                  groups={groups()}
                  speeds={speeds}
                  customSpeeds={customSpeeds}
                  products={products}
                  setProducts={setProducts}
                ></CurveSpeedDefinitionStep>
              )}
            />
            <Step
              id="finish"
              render={({ previous }) => (
                <ValidationForm onSubmit={() => {}}>
                  <Card className="mt-2">
                    <CardHeader>
                      <FormattedMessage id="product_wizard.finish" defaultMessage="Finish" />
                    </CardHeader>
                    <CardBody>
                      <table className="table align-items-center">
                        <thead className="thead-light">
                          <tr>
                            <th>
                              <FormattedMessage id="product_information.column_modelo_producto" defaultMessage="Modelo Producto" />
                            </th>
                            <th>
                              <FormattedMessage id="product_information.column_series" defaultMessage="Series" />
                            </th>
                            <th>
                              <FormattedMessage id="product_information.column_concept_class" defaultMessage="Concept class" />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {finalProducts.map((p, i) => (
                            <SummaryRow product={p} index={i} lookupConceptClass={lookupConceptClass} key={i}></SummaryRow>
                          ))}
                        </tbody>
                      </table>
                    </CardBody>
                    <CardFooter>
                      <Button className="btn btn-success float-right" onClick={finish}>
                        <i className="fas fa-check"></i> <FormattedMessage id="product_wizard.finalise_product_definitions" defaultMessage="Finalise product definitions" />
                      </Button>
                      <Button className="btn btn-outline-danger" onClick={previous}>
                        <i className="fas fa-arrow-left"></i> <FormattedMessage id="app.back" defaultMessage="Back" />
                      </Button>
                    </CardFooter>
                  </Card>
                </ValidationForm>
              )}
            />
          </Steps>
        </div>
      )}
    ></Wizard>
  );
}

function SummaryRow({ product, lookupConceptClass, index }) {
  const [showGroups, setShowGroups] = useState(false);

  return (
    <>
      <tr>
        <td>
          <b>{product.name || `Product ${index + 1}`}</b>
        </td>
        <td>{product.series.name}</td>
        <td>{lookupConceptClass(product.conceptClassUnic)}</td>
      </tr>
      {!showGroups ? (
        <tr>
          <td>
            <Tooltip text="Show groups summary">
              <button type="button" className="btn btn-sm btn-link text-dark" onClick={() => setShowGroups(true)}>
                <i className="fas fa-plus"></i>
              </button>
            </Tooltip>
          </td>
          <td colSpan="2">
            <FormattedMessage id="product_summary.number_of_groups" defaultMessage="Number of groups:" /> {product.curveGroups.length}
          </td>
        </tr>
      ) : (
        <tr>
          <td className="p-0" colSpan="3">
            <table className="table align-items-center mb-0">
              <thead>
                <tr>
                  <th style={{ width: 80 }}>
                    <Tooltip text="Hide groups summary">
                      <button type="button" className="btn btn-sm btn-link text-dark" onClick={() => setShowGroups(false)}>
                        <i className="fas fa-minus"></i>
                      </button>
                    </Tooltip>
                  </th>
                  <th>
                    <FormattedMessage id="product_summary.groups" defaultMessage="Groups" />
                  </th>
                  <th>
                    <FormattedMessage id="product_summary.filter_principal_mg" defaultMessage="Filter Principal MG" />
                  </th>
                  <th>
                    <FormattedMessage id="product_summary.concept_class" defaultMessage="Concept class" />
                  </th>
                </tr>
              </thead>
              {product.curveGroups.map((g, j) => (
                <Fragment key={j}>
                  <tr>
                    <th className="p-0 bg-secondary"></th>
                    <th scope="row">
                      {j + 1}. {g.modeloGrupoCurva}
                    </th>
                    <td>{g.filterPrincipalMG}</td>
                    <td>{lookupConceptClass(g.conceptClassUnic)}</td>
                  </tr>
                  <tr>
                    <td className="p-0" colSpan="4">
                      <table className="table align-items-center mb-0">
                        <thead>
                          <tr>
                            <th style={{ width: 160 }}></th>
                            <th>
                              <FormattedMessage id="product_summary.curves" defaultMessage="Curves" />
                            </th>
                            <th className="text-center" style={{ width: 120 }}>
                              <FormattedMessage id="product_summary.principal" defaultMessage="Principal" />
                            </th>
                          </tr>
                        </thead>
                        {g.curves.map((c, k) => (
                          <Fragment key={k}>
                            <tr>
                              <th className="p-0 bg-secondary"></th>
                              <th scope="row">
                                {k + 1}. {c.tension !== undefined && `${c.tension}V`}
                                {c.frecuencia !== undefined && `${c.frecuencia}Hz`}
                                {c.speed !== undefined && `${c.speed}`}
                              </th>
                              <td className="text-center">
                                {c.principal || g.curves.length === 1 ? <i className="text-success fas fa-circle"></i> : <i className="far fa-circle"></i>}
                              </td>
                            </tr>
                          </Fragment>
                        ))}
                      </table>
                    </td>
                  </tr>
                </Fragment>
              ))}
            </table>
          </td>
        </tr>
      )}
      <tr>
        <td className="p-0 bg-dark" style={{ height: 2 }} colSpan="4"></td>
      </tr>
    </>
  );
}

export default SimpleProductWizard;
