import Index from "./views/Index.jsx";
import Login from "./views/Login.jsx";
import Maintenance from "./views/Maintenance.jsx";
import CurvesGeneratorIndex from "./views/CurvesGenerator/Index.jsx";
import ProductCreationIndex from "./views/ProductCreation/Index.jsx";
import LaboratoryTestsIndex from "./views/LaboratoryTests/Index.jsx";
import DatosPlacaIndex from "./views/DatosPlaca/Index.jsx";
import CCP from "./views/CCP/Home.jsx";
import SeriesLoadingsIndex from "./views/SeriesLoadings/Index.jsx";
import AdminActions from "./views/AdminActions/Home.jsx";
import CertificatesIndex from "./views/Certificates/Index.jsx";

const routes = [
  {
    path: "/home",
    name: "Home",
    language_key: "routes.home",
    icon: "fas fa-home",
    component: Index,
    area: "app",
    exact: true,
    sidebar: true,
  },
  {
    path: "/login",
    name: "Login",
    language_key: "routes.login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    area: "auth",
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    language_key: "routes.maintenance",
    component: Maintenance,
    area: "auth",
  },
  {
    path: "/products",
    name: "Products",
    language_key: "routes.products",
    icon: "fas fa-fan",
    component: ProductCreationIndex,
    area: "app",
    sidebar: true,
  },
  {
    path: "/laboratory-tests",
    name: "Laboratory Tests",
    language_key: "routes.laboratory_tests",
    icon: "fas fa-clipboard-list",
    component: LaboratoryTestsIndex,
    area: "app",
    sidebar: true,
  },
  {
    path: "/curves-generator",
    name: "Curves Generator",
    language_key: "routes.curve_generator",
    icon: "fas fa-chart-area",
    component: CurvesGeneratorIndex,
    area: "app",
    sidebar: true,
  },
  {
    path: "/ccp",
    name: "CCP Codification",
    language_key: "routes.ccp",
    icon: "fas fa-book",
    component: CCP,
    area: "app",
    sidebar: true,
  },
  {
    path: "/datos-placa",
    name: "Datos Placa",
    language_key: "routes.datos_placa",
    icon: "fas fa-digital-tachograph",
    component: DatosPlacaIndex,
    area: "app",
    sidebar: true,
  },
  {
    path: "/certificates",
    name: "Certificates",
    language_key: "routes.certificates",
    icon: "fas fa-stamp",
    component: CertificatesIndex,
    area: "app",
    sidebar: true,
    //roles: ["UserAdmin"],
  },
  {
    path: "/manage-loadings",
    name: "Manage Loadings",
    language_key: "routes.manage_loadings",
    icon: "fas fa-book",
    component: SeriesLoadingsIndex,
    area: "app",
    sidebar: true,
    roles: ["UserAdmin"],
  },
  {
    path: "/admin-actions",
    name: "Admin Actions",
    language_key: "routes.admin_actions",
    icon: "fas fa-cog",
    component: AdminActions,
    area: "app",
    sidebar: true,
    roles: ["UserAdmin"],
  },
];
export default routes;
